<template>
  <div class="app">
    <AppHeader fixed>
    <vue-progress-bar></vue-progress-bar>
      <SidebarToggler class="d-lg-none" display="md" mobile />
      <b-link class="navbar-brand" to="#">
        <img class="navbar-brand-full" src="img/brand/logo.svg" width="89" height="25" alt="N Zone Spors Logo">
        <img class="navbar-brand-minimized" src="img/brand/nzone.svg" width="30" height="30" alt="N Zone Spors Logo">
      </b-link>
      <SidebarToggler class="d-md-down-none" display="lg" />
      <b-navbar-nav class="ml-auto">
        <b-nav-item class="d-md-down-none">
          <DefaultHeaderDropdownNotif/>
        </b-nav-item>
        <DefaultHeaderDropdownAccnt/>
      </b-navbar-nav>
      <!-- AsideToggler class="d-none d-lg-block" / -->
      <!--<AsideToggler class="d-lg-none" mobile />-->
    </AppHeader>
    <div class="app-body" style="position: relative;">
      <Working />
      <AppSidebar fixed>
        <SidebarHeader/>
        <SidebarForm/>
        <SidebarNav :navItems="nav"></SidebarNav>
        <SidebarFooter/>
        <SidebarMinimizer/>
      </AppSidebar>
      <main class="main">
        <Breadcrumb class="d-print-none" :list="list" />
        <div class="container-fluid">
          <b-button
            v-if="isRefresh"
            class="mb-2"
            variant="warning"
            size="large"
            @click="updateApp"
            block
          >
          <b-spinner variant="success" small type="grow" label="Spinning"></b-spinner> 
          A New Update is Availailable! <b-spinner variant="success" small type="grow" label="Spinning"></b-spinner>
          </b-button>
          <SelectActiveBusiness class="mb-4" />
          <router-view></router-view>
        </div>
      </main>
      <!--aside-->
      <!-- AppAside fixed>
        <DefaultAside/>
      </AppAside -->
    </div>
    <Footer class="mt-4">
      <!--footer-->
    </Footer>
  </div>
</template>

<script>

import nav from '@/_nav'
import { Header as AppHeader, SidebarToggler, Sidebar as AppSidebar, SidebarFooter, SidebarForm, SidebarHeader, SidebarMinimizer, SidebarNav, Aside as AppAside, AsideToggler, Breadcrumb } from '@coreui/vue'
import DefaultAside from './DefaultAside'
import Footer from '../views/Footer'
import Working from './Working'
import DefaultHeaderDropdown from './DefaultHeaderDropdown'
import DefaultHeaderDropdownNotif from './DefaultHeaderDropdownNotif'
import DefaultHeaderDropdownAccnt from './DefaultHeaderDropdownAccnt'
import DefaultHeaderDropdownMssgs from './DefaultHeaderDropdownMssgs'
import DefaultHeaderDropdownTasks from './DefaultHeaderDropdownTasks'
import SelectActiveBusiness from '@/components/SelectActiveBusiness'

export default {
  name: 'DefaultContainer',
  components: {
    Working,
    AsideToggler,
    AppHeader,
    AppSidebar,
    AppAside,
    Footer,
    Breadcrumb,
    DefaultAside,
    DefaultHeaderDropdown,
    DefaultHeaderDropdownMssgs,
    DefaultHeaderDropdownNotif,
    DefaultHeaderDropdownTasks,
    DefaultHeaderDropdownAccnt,
    SidebarForm,
    SidebarFooter,
    SidebarToggler,
    SidebarHeader,
    SidebarNav,
    SidebarMinimizer,
    SelectActiveBusiness
  },
  data: () => ({
    registration: null,
    isRefresh: false,
    refreshing: false,
  }),
  computed: {
    nav () {
      let ret = ''
      let role = this.$store.getters.currentUser.roles || ['ROLE_NONE']
      switch (role[0]) {
      case 'ROLE_ADMIN':
        ret = nav.admin
        break
      case 'ROLE_DIRECTOR':
        ret = nav.director
        break
      case 'ROLE_COACH_JR':
        ret = nav.coach_jr
        break
      case 'ROLE_COACH':
        ret = nav.coach
        break
      default:
        ret = []
      }
      return ret
    },
    name () {
      return this.$route.name
    },
    list () {
      return this.$route.matched.filter((route) => route.name || route.meta.label )
    }
  },
  mounted () {
    document.addEventListener('serviceWorkerUpdateEvent', this.appUpdateUI, {
      once: true,
    })
  },
  watch: {
    '$route': {
      handler () {
      // username update (hack for legacy to current)
      if (this.currentUser.isAuthenticated &&
        // !this.currentUser.originalUser &&
        this.$route.name !== 'UserProfile' &&
        this.$store.getters.currentUser.fullName === this.$store.getters.currentUser.username)
        this.$router.push({
          name: 'UserProfile',
          query: {
            action: 'profile-update'
          }
        })
      }
    }
  },
  methods: {
    appUpdateUI: function (e) {
      this.registration = e.detail
      this.isRefresh = true
    },
    updateApp() {
      this.isRefresh = false
      if (this.registration || this.registration.waiting) {
        this.registration.waiting.postMessage({ type: 'SKIP_WAITING' })
      }
      navigator.serviceWorker.addEventListener('controllerchange', () => {
        if (this.refreshing) return
        this.refreshing = true
        window.location.reload()
      })
    }
  }
}
</script>